$poly-sans: 'PolySans', sans-serif;

$font-neutral: 400;
$font-median: 500;

$content-max-width: 73.75rem;

// ================ OFFICIAL BRAND REFRESH COLOR PALETTE ================

// ================ BRAND BLUE  =================
$brand-blue-80: #4248ff;
$brand-blue-100: #2015ff;
$brand-blue-120: #0000cc;
$brand-blue-140: #142580;
$brand-blue-160: #171a53;
// ==============================================

// ================== PURPLE ====================
$purple-80: #b181ff;
$purple-100: #9e62ff;
$purple-120: #7e4ecc;
// ==============================================

// =============== LIGHT BLUE ===================
$light-blue-80: #6597ff;
$light-blue-100: #3f7dff;
$light-blue-120: #3264cc;
// ==============================================

// ================ SEA FOAMS ===================
$seafoam-80: #33d1b4;
$seafoam-100: #00c5a1;
$seafoam-120: #00a687;
// ==============================================

// ================= LIME =======================
$lime-80: #caee3a;
$lime-100: #bdea09;
$lime-120: #a6cc00;
// ==============================================

// ============= BLACK/WHITE/GREY ===============
$grey-80: #f6f6f6;
$grey-100: #cbd5de;
$grey-120: #7d97ad;
$grey-140: #536483;
$text-black: #0b0b0b;
$text-white: #ffffff;
// ==============================================

$error-red: #d73a01;

@function calculateRem($size) {
  $remSize: #{$size / 16}rem;
  @return $remSize;
}

@mixin font-size($size) {
  font-size: #{$size}px; //Fallback in px
  font-size: calculateRem($size);
}

@mixin line-height($size) {
  line-height: #{$size}px; //Fallback in px
  line-height: calculateRem($size);
}
