@import '../../assets/styles/shared';
@import '../../assets/styles/brand-refresh';

.tabbed-pane {
  margin-left: auto;
  margin-right: auto;
  max-width: 620px;
  text-align: center;
}

.tabs {
  display: flex;
  gap: 1px;
}

._tab {
  all: unset;
  outline: revert;
  flex: auto;
  padding: $vds-spacing-2x;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
  box-shadow: none;
  border-radius: 4px 4px 0px 0px;
}

.tab {
  composes: _tab;
  cursor: pointer;
  background: $grey-80;
  font-weight: bold;
  color: $grey-140;
}

.tab-selected {
  composes: _tab;
  cursor: default;
  background: $vds-color-white;
  font-weight: bold;
  color: $text-black;

  &:hover,
  &:focus {
    box-shadow: none;
  }
}

.content {
  background: $vds-color-white;
  padding: 40px 60px;
  border-radius: 4px;

  label {
    text-align: start;
  }
}

@include mq($until: tablet) {
  .content {
    padding: 30px;
  }
}

body[dir='rtl'] {
  ._tab + ._tab {
    border-left: 0;
    border-right: 1px solid $vds-color-silver-lighter;
  }
}
