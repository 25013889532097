@import '../../assets/styles/shared';

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 780px;
  text-align: center;
}

.card {
  box-shadow: 2px 2px 3px 0 rgba(117, 124, 129, 0.12);
  border: 1px solid $vds-color-silver-lighter;
  background: $vds-color-white;
  padding: 80px;
}

.content {
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
}

.completed {
  max-width: 400px;
  margin: 0 auto;
}

.image {
  img {
    width: 160px;
  }
}

.redirect {
  composes: content;
  margin-top: 40px;
  font-size: 16px;
  line-height: 1.5;
}

.header {
  margin-top: 30px;
  font-size: 34px;
  font-weight: 300;
  line-height: 1.5;
  color: $vds-color-slate;
  text-align: center;
}

@include mq($until: tablet) {
  .header {
    font-size: 24px;
  }

  .card {
    padding: 40px;
  }
}
