@import '../../assets/styles/shared';
@import '../../assets/styles/brand-refresh';

.contain {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0;
  &::before,
  &::after {
    content: '';
    flex: 1;
    border-top: 1px solid $grey-100;
  }
}

.or {
  position: relative;
  align-self: baseline;
  margin-left: 26px;
  margin-right: 26px;
  color: $text-black;
  font-family: $poly-sans;
  font-weight: $font-neutral;
  @include font-size(16);
  @include line-height(30);
}
