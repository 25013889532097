@import '../../../assets/styles/shared';
@import '../../../assets/styles/brand-refresh';

.provider-button {
  width: 100%;
  border: none;
  color: $text-black;
  text-transform: none;
  border-radius: $form-element-border-radius;
  letter-spacing: 0;
  min-width: 200px;
  max-width: 240px;
  margin: 0 auto;
  border: 1px solid $grey-100;

  button:focus {
    box-shadow: 0 0 0 4px $brand-blue-100;
  }
}

@include mq($from: tablet) {
  .provider-button + .provider-button {
    margin-left: 15px;
  }
}

@include mq($until: tablet) {
  .provider-button {
    margin-top: 10px;
  }
}

.button-wrapper--lowercase button {
  color: $text-black;
  letter-spacing: normal;
  text-transform: none;
  font-weight: 500;
  @include font-size(14);
  @include line-height(26);

  :global(i.vds-icon) {
    width: 40px;
    height: 40px;
  }
}

.label {
  display: flex;
  align-items: center;
  justify-content: center;
  .logo {
    height: 24px;
    width: 24px;
    margin-right: 15px;
  }
}

body[dir='rtl'] {
  .label {
    .logo {
      margin-right: 0px;
      margin-left: 15px;
    }
  }

  @include mq($from: tablet) {
    .provider-button + .provider-button {
      margin-left: 0;
      margin-right: 15px;
    }
  }
}
