@import '../../assets/styles/shared';

.info {
  background: $vds-color-white;
  padding: 50px;
}
.header {
  font-size: 34px;
  font-weight: 300;
  line-height: 1.3;
  color: $vds-color-slate;
  text-align: center;
}

.description {
  font-size: 14px;
  line-height: 1.6;
  color: $vds-color-silver-light;
  max-width: 460px;
  margin: 20px auto 0;
  text-align: center;
  max-width: 390px;
}

.footer {
  background: #f4f4ef;
  padding: 50px 80px;
}

.form {
  display: flex;
  button {
    padding: 1.2em 0;
    width: 100%;
    margin-left: 20px;
    min-width: 150px;
  }
}

.input {
  width: 100%;
}

.alert {
  margin-bottom: 30px;
}

body[dir='rtl'] {
  .form {
    button {
      margin-left: 0;
      margin-right: 20px;
    }
  }
}

@include mq($until: tablet) {
  .footer {
    padding: 30px;
  }

  .form {
    flex-direction: column;

    button {
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-top: 20px;
    }
  }
}
