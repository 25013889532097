@import '../../../assets/styles/shared';
@import '../../../assets/styles/brand-refresh';

.header {
  background: $vds-color-white;
  padding: 8px 24px;
  box-shadow: 0px 2px 4px -1px #0b0b0b0f, 0px 4px 6px -1px #0b0b0b1a;
}

.layout {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: $content-max-width;
  margin: 0 auto;
}

.logo {
  display: block;
  width: 127px;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

@include mq($from: tablet) {
  .logo {
    width: 170px;
  }
}
