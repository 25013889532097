@import '../../assets/styles/brand-refresh';

.revealable_password {
  position: relative;

  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
}

.icon_button {
  all: unset;
  outline: revert;
  cursor: pointer;
  position: absolute;
  bottom: 8px;
  right: 8px;

  :global(.vds-icon.vds-color--silver) {
    color: $text-black !important;
  }
}
