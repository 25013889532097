@import '../../assets/styles/shared';
@import '../../assets/styles/brand-refresh';

._alert {
  padding: 20px 25px;
  border-radius: 4px;
  color: $vds-color-white;
  text-align: left;
}

.success {
  composes: _alert;
  color: $text-black;
  background-color: rgba(51, 209, 180, 0.6);
}

.error {
  composes: _alert;
  background-color: $error-red;
}

.hidden {
  display: none;
}

body[dir='rtl'] {
  ._alert {
    text-align: right;
  }
}
