@font-face {
  font-family: 'PolySans';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/PolySans-Neutral.woff) format('woff');
}
@font-face {
  font-family: 'PolySans';
  font-style: bold;
  font-weight: 500;
  src: url(../fonts/PolySans-Median.woff) format('woff');
}
