@import '../../../assets/styles/shared';

.card {
  box-shadow: 2px 2px 3px 0 rgba(117, 124, 129, 0.12);
  border: 1px solid $vds-color-silver-lighter;
  background: $vds-color-white;
  padding: 16px;

  @media screen and (min-width: 30em) {
    padding: 24px;
  }

  @media screen and (min-width: 48em) {
    padding: 80px;
  }
}

.content {
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  word-wrap: break-word;
}
