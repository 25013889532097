@import '../assets/styles/shared';
@import '../assets/styles/brand-refresh';

:global {
  html {
    background-color: $grey-80;
  }

  body {
    // Note: we need to override the white background color added to the body by vertias.
    background-color: transparent !important;
  }

  body * {
    box-sizing: border-box;
  }

  a.forgot-password {
    font-size: 14px;
    font-weight: normal;
  }

  // Tos styles for all forms.
  .tos-blurb {
    @include font-size(12);
    @include line-height(20);
    color: $text-black;
    max-width: 500px;
    margin: 20px 0;
    text-align: center;

    a {
      text-decoration: underline;
      color: $text-black;
      font-weight: 500;
    }
  }
  body[dir='rtl'] {
    .tos-blurb {
      text-align: right;
    }
  }
}

.content {
  padding: 40px;
}

.brandRefresh {
  // Inputs
  :global(.vds-text-input) {
    margin-bottom: 15px;
  }

  :global(.vds-text-input.vds-text-input--error .vds-text-input__input) {
    border-color: $error-red;
    border-width: 2px;
  }

  :global(.vds-text-input__input) {
    border-color: $grey-100;

    &:required:focus,
    &:focus {
      box-shadow: 0 0 0 3px $brand-blue-100;
    }

    &::placeholder {
      color: $grey-140;
    }
  }

  :global(.vds-form-validation.vds-form-validation--error) {
    color: $text-black;
  }

  // Buttons
  :global(.vds-button.vds-button--primary) {
    position: relative;
    background-color: $brand-blue-100;
    border-radius: 4px;
    box-shadow: none;
    text-transform: unset;
    letter-spacing: normal;
    font-weight: 700;
    @include font-size(18);
    @include line-height(28);
    width: 100%;
    max-width: 240px;
    margin: 0 auto;
    height: 60px;
    padding: 0 24px;

    span::after {
      content: '→';
      margin-left: auto;
      @include font-size(26);
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: transparent;
      border-radius: 4px;
      border: solid 2px;
      border-color: transparent;
      transition: all 0.1s;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &:focus-visible {
      &:before {
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        border-color: $brand-blue-100;
      }
    }
  }

  :global(.vds-button.vds-button--resend) {
    background-color: $brand-blue-100;
    color: #ffffff;
    border-radius: 4px;
    box-shadow: none;
    text-transform: unset;
    letter-spacing: normal;
    font-weight: 700;
    @include font-size(18);
    @include line-height(28);
    width: 100%;
    max-width: 240px;
    height: 60px;
    padding: 0 24px;
  }

  :global(.vds-button.vds-button--resend-subtle) {
    background-color: white;
    color: $grey-140;
    border-radius: 4px;
    box-shadow: none;
    text-transform: unset;
    letter-spacing: normal;
    font-weight: 400;
    @include font-size(16);
    @include line-height(28);
    width: 100%;
    max-width: 240px;
    height: 20px;
    padding: 0 8px;
  }

  // Anchor Links
  :global(.brand-refresh-link) {
    color: $brand-blue-100;

    &__underline {
      color: $text-black;
      text-decoration: underline;
      font-weight: 400;
    }
  }
}

// Form Legends
:global(.vds-form-fieldset__title:empty) {
  display: none;
}

// Error Messages
:global(.vds-form-validation.vds-form-validation--error) {
  background: #fbe6e6;
}

@include mq($until: tablet) {
  .content {
    padding: 25px;
  }
}
