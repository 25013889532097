@import '../../assets/styles/shared';
@import '../../assets/styles/brand-refresh';

.header {
  text-align: center;
  color: $text-black;
  font-family: $poly-sans;
  font-weight: $font-neutral;
  @include font-size(32);
  @include line-height(42);
}

.subheader {
  text-align: center;
  color: $text-black;
  font-family: $poly-sans;
  font-weight: $font-neutral;
  @include font-size(28);
  @include line-height(42);
}

.description {
  color: $text-black;
  margin: 15px 0;
  text-align: center;
  @include font-size(16);
  @include line-height(26);
}

@include mq($from: tablet) {
  .header {
    @include font-size(42);
    @include line-height(46);
  }
}
